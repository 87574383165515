import './App.css';
import { Typography } from "@mui/material";

function Footer() {
    const currentYear = new Date().getFullYear();
    return (
        <div className="app-footer">
            <Typography variant="subtitle1" className="bottom-space">For questions about CPS or issues with the site contact us at <a href="mailto:cps@kc.frb.org">cps@kc.frb.org</a>.</Typography>
            <Typography component="div" variant="body">&copy;{currentYear} Federal Reserve Bank of Kansas City</Typography>
        </div>
        
    )
}

export default Footer;
